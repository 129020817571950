import React from "react";
import PropTypes from "prop-types";

import Box from "../containers/box";
import Text from "../typography/text";
import theme from "../../utils/theme";

const CarouselQuote = ({ quote, highlight, ...rest }) => {
  return (
    <Box {...rest}>
      <Text
        fontSize={[`24px`, null, `26px`, null, `28px`]}
        color={theme.blueDark}
      >
        {highlight}
      </Text>
      <Text mb="0">&quot;{quote}&quot;</Text>
    </Box>
  );
};

CarouselQuote.propTypes = {
  quote: PropTypes.PropTypes.string.isRequired,
  highlight: PropTypes.PropTypes.string.isRequired,
};

export default CarouselQuote;
