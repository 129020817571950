import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Flex from "../containers/flex";

const Root = styled(Flex)`
  > div {
    width: 100%;
  }
`;

const CarouselItem = ({ children, ...rest }) => (
  <Root {...rest}>{children}</Root>
);

CarouselItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default CarouselItem;
