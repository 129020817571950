import { useState } from "react";

export default function useCarousel(numberOfItems) {
  const [slideIndex, setSlideIndex] = useState(0);

  function onDrag(index) {
    setSlideIndex(index);
  }

  function onNextClick() {
    if (slideIndex + 1 === numberOfItems) {
      setSlideIndex(0);
    } else {
      setSlideIndex(slideIndex + 1);
    }
  }

  return { slideIndex, onDrag, onNextClick };
}