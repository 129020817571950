import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import Text from "../typography/text";
import LinkButton from "./linkButton";
import LinkWithIcon from "./linkWithIcon";

const Root = styled.div`
  ${p => p.setCenter
    && `
      margin: auto;
      text-align: center;
    `}

  ${p => p.howItWorks
    && `
      svg {
        margin-left: 8px;
        vertical-align: middle;
      }

      a:first-of-type {
        margin-right: 10px;
      }

      a:last-of-type {
        margin-top: 20px;
        display: inline-block;
      }

      @media (min-width: ${p.theme.screenL}) {
        a:first-of-type {
          margin-right: 30px;
        }
      }
    `}

    @media (min-width: ${p => p.theme.screenM}) {
      max-width: ${p => p.setWidth};
    }
`;

const TextWithLink = ({
  children,
  href,
  internal,
  buttonText,
  howItWorks,
  setCenter,
  setWidth,
  theme,
}) => {
  return (
    <Root setWidth={setWidth} howItWorks={howItWorks} setCenter={setCenter}>
      <Text>{children}</Text>

      {internal ? (
        <LinkButton to={href}>{buttonText}</LinkButton>
      ) : (
        <LinkButton as="a" href={href}>
          {buttonText}
        </LinkButton>
      )}

      {howItWorks && (
        <LinkWithIcon to="/" icon="PlayButton" fill={theme.greyDark}>
          See how it works
        </LinkWithIcon>
      )}
    </Root>
  );
};

TextWithLink.propTypes = {
  children: PropTypes.string.isRequired,
  href: PropTypes.string,
  setWidth: PropTypes.string,
  howItWorks: PropTypes.bool,
  internal: PropTypes.bool,
  setCenter: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(TextWithLink);
