import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Arrow } from "../svg/icons";

const Root = styled.button`
  border-style: none;
  background-color: rgba(48, 184, 66, 0.8);

  color: white;
  padding: 0 20px;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: ${p => p.theme.transitionDefault};

  @media (min-width: ${p => p.theme.screenM}) {
    background-color: #30b842;
    padding: 37px;
    border-radius: 100%;
  }

  &:hover {
    cursor: pointer;
    background: rgba(35, 149, 50, 0.9);

    @media (min-width: ${p => p.theme.screenM}) {
      background-color: #2fa73d;
    }
  }

  svg {
    vertical-align: middle;
  }
`;

const CarouselButton = ({ onClick }) => (
  <Root onClick={onClick}>
    <Arrow fill="white" width="25px" />
  </Root>
);

CarouselButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CarouselButton;
