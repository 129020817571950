import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { graphql } from "gatsby";

import SEO from "../components/globals/seo";
import Section from "../components/containers/section";
import Box from "../components/containers/box";
import Flex from "../components/containers/flex";
import Image from "../components/globals/image";
import Layout from "../components/containers/layout";
import Container from "../components/containers/container";
import Heading from "../components/typography/heading";
import Text from "../components/typography/text";
import TextWithLink from "../components/links/textWithLink";
import { Absolute, Relative } from "../components/containers/position";
import MaxWidthAbsolute from "../components/containers/maxWidthAbsolute";
import AppStoreLinks from "../components/links/appstoreLinks";
import PageTitle from "../components/typography/pageTitle";
import Circle from "../components/globals/circle";

import Carousel from "../components/carousel/carousel";
import CarouselItem from "../components/carousel/carouselItem";
import CarouselQuote from "../components/carousel/carouselQuote";
import CarouselButton from "../components/carousel/carouselButton";
import Counter from "../components/carousel/counter";
import ProfileComponent from "../components/profile/profile";
import useCarousel from "../hooks/useCarousel";

const Profile = styled(ProfileComponent)`
  position: absolute;
  z-index: 5;

  @media (min-width: ${p => p.theme.screenM}) {
    top: 90%;
    left: 7%;
  }
  @media (min-width: 1400px) {
    top: 92%;
  }
`;

const ImageWrap = styled(Relative)`
  max-height: 625px;

  img {
    margin: 0 auto;
    max-height: 625px;
    border-radius: 10px;
  }
`;

const QuoteWrap = styled(Box)`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 130px;

  @media (min-width: ${p => p.theme.screenM}) {
    max-width: 420px;
    transform: translateX(130px);
    margin-top: 40px;
  }
  @media (min-width: ${p => p.theme.screenL}) {
    max-width: 620px;
  }
`;

const CoachPortalPage = ({ data, theme }) => {
  const testimonialList = data?.prismic?.data?.testimonialList || [];
  const hasMultipleItems = testimonialList.length > 1;
  const { slideIndex, onDrag: onCarouselDrag, onNextClick: onCarouselNextClick } = useCarousel(testimonialList.length);

  return (
    <Layout>
      <SEO
        title="Coach Portal"
        description="Find out how coaches can monitor and control their team's progress through the TopTekkers app."
        path="/coach-portal"
      />

      <Section>
        <Container>
          <PageTitle>FOR COACHES</PageTitle>
        </Container>

        <Flex
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          className="posRelative"
          maxWidth="1600px"
          mx="auto"
        >
          <Container width={[1, null]} order={[2, null, 1]}>
            <Heading as="h1" maxWidth={[null, null, 480, 580]}>
              Create teams to track the individual progress of your players
            </Heading>
          </Container>

          <Absolute
            position={[null, null, `absolute`]}
            width={[1, null, 1 / 2]}
            order={[1, null, 2]}
            mb={[20, null, 0]}
            pl={[20, 30, 0]}
            right={[null, null, -12, null, 0]}
            bottom={[`-26px`, null, `-40px`]}
          >
            <Image
              fluid={data.teamPitch.childImageSharp.fluid}
              alt="Illustration of team players on football pitch"
              maxWidth={[null, null, 822]}
              borderRadius="10px 0 0 10px"
            />

            <Circle
              growMid
              bgColor={theme.yellow}
              top={[`60%`]}
              left={[-24, null, -20]}
              hideOnMobile
            />
            <Circle
              grow
              bgColor={theme.pink}
              top={[-24, null, -36, -72]}
              right={[`30%`]}
            />
          </Absolute>

          <Container
            width={[1, null]}
            order={[3, null, 1]}
            maxWidth={1200}
            alignSelf="flex-start"
          >
            <TextWithLink
              setWidth="370px"
              btnColor={theme.green}
              buttonText="Try it for Free!"
              href="https://app.toptekkers.com"
            >
              Connect with players in your team, so you can share team
              training plans with them and manage and track their progress
              and participation
            </TextWithLink>
          </Container>

          <Circle
            grow
            bgColor={theme.purple}
            top={[-70, null, -90, -170]}
            right={[-30, null, -46, -83]}
          />
          <Circle
            grow
            bgColor={theme.orange}
            top={`20%`}
            left={[-30, null, -120, null, -90]}
          />
        </Flex>
      </Section>

      <Section>
        <Container>
          <Heading as="h2" maxWidth={[null, null, 578]}>
            Set individual training plans that are tailored to specific players
          </Heading>
          <Flex flexWrap="wrap">
            <Box
              width={[1, null, 1 / 2]}
              mb={[30, null, 0]}
              className="posRelative"
            >
              <Image
                fluid={data.coachPlan.childImageSharp.fluid}
                alt="Illustration of coach training plan tasks"
              />
              <Circle
                growMid
                bgColor={theme.red}
                bottom={[-30, null, -38]}
                right={[-30, null, -28]}
              />
              <Circle
                grow
                bgColor={theme.blue}
                top={[24, null, 36, 72]}
                left={[-30, null, -46, -83]}
              />
            </Box>

            <Box
              width={[1, null, 1 / 2]}
              max-Width={[null, null, null, 370]}
              pl={[0, null, 30, 40, 70]}
            >
              <TextWithLink
                btnColor={theme.green}
                buttonText="Create an account"
                href="https://app.toptekkers.com"
              >
                Every player is different.  That&apos;s why TopTekkers allows
                you to assign individual training plans to ensure players are
                focusing on the right things to help improve their game away
                from the training ground.  Players can view the techniques and
                challenges you have assigned them in their app.
              </TextWithLink>
            </Box>
          </Flex>
        </Container>
      </Section>
      
      {testimonialList.length > 0 && (
      <Section>
        <Container>
          <Heading
            as="h2"
            textAlign="center"
            mx="auto"
            mb={[`40px`, null, null, `60px`, `80px`]}
            maxWidth="578px"
            className="posRelative"
          >
              Used by the World’s best coaches
          </Heading>

          <Circle
            grow
            bgColor={theme.yellow}
            top="0"
            right={[-30, null, -46, -83]}
          />
        </Container>

        <Carousel
          slideIndex={slideIndex}
          onCarouselDrag={onCarouselDrag}
          hasMultipleItems={hasMultipleItems}
        >
          {testimonialList.map((entry, i) => {
            const { data: entryData } = entry.testimonial.document;
            const mainImage = entryData.main_image.localFile.childImageSharp.fluid;
            const profileData = { name: entryData.profile_name, title: entryData.profile_title, img: entryData.profile_image };

            return (
            // eslint-disable-next-line react/no-array-index-key
              <Container key={i}>
                <CarouselItem flexDirection="column">
                  <ImageWrap>
                    <Image fluid={mainImage} alt={entryData.main_image.alt} />

                    <Profile
                      maxWidth={[`100%`, null, `300px`]}
                      data={profileData}
                      mt={[`20px`, null, 0]}
                      alignItems={[`center`, null, `flex-start`]}
                      rowOnMob
                    />

                    {hasMultipleItems && (
                      <>
                        <Absolute
                          top={[`0`, null, `unset`]}
                          bottom={[`0`, null, `10%`]}
                          right={[`0`, null, `-46px`]}
                          zIndex="5"
                        >
                          <CarouselButton onClick={onCarouselNextClick} />
                        </Absolute>

                        <Counter
                          current={i + 1}
                          max={testimonialList.length}
                        />
                      </>
                    )}

                    <Circle
                      grow
                      bgColor={theme.pink}
                      bottom="45%"
                      left={[-30, null, -46, -83]}
                      hideOnMobile
                    />
                  </ImageWrap>

                  <QuoteWrap>
                    <CarouselQuote
                      quote={entryData.testimonial}
                      highlight={entryData.highlight}
                    />
                  </QuoteWrap>
                </CarouselItem>
              </Container>
            );
          })}
        </Carousel>
      </Section>
      )}

      <Section>
        <Container>
          <Flex flexWrap="wrap" alignItems="center">
            <Box
              width={[1, null, 1 / 2]}
              order={[2, null, 1]}
              pr={[0, null, 30, 40, 70]}
            >
              <Heading as="h2" setWidth="518px">
                Coach on the go with the TopTekkers app
              </Heading>

              <Text>
                Whether you want to assign a new team or individual training
                plan, add a player to your team or see who&apos;s smashing their
                performance - it&apos;s all available in the TopTekkers app.
              </Text>

              <AppStoreLinks />
            </Box>

            <Relative
              width={[1, null, 1 / 2]}
              order={[1, null, 2]}
              mb={[20, null, 0]}
              className="posRelative"
            >
              <Image
                fluid={data.coachOnTheGo.childImageSharp.fluid}
                alt="Mobile app views showing how coaches can monitor their teams and players whilst on the go."
              />

              <Circle
                growMid
                bgColor={theme.pink}
                bottom={`30%`}
                right={[-30, null, -42]}
              />
              <Circle
                grow
                bgColor={theme.yellow}
                top={[-6, null, -36, -60]}
                left={[`46%`, null, `38%`]}
              />
            </Relative>
          </Flex>
        </Container>
      </Section>

      <Section maxWidth="none" pb="0">
        <MaxWidthAbsolute>
          <Circle
            grow
            bgColor={theme.yellow}
            top={[-28, null, -46, -76]}
            left={[24, null, 36, 72]}
          />
          <Circle
            growMid
            bgColor={theme.blue}
            bottom={`20%`}
            left={`14%`}
            hideOnMobile
          />
          <Circle
            grow
            bgColor={theme.red}
            top={`44%`}
            right={[-28, null, -46, -90]}
            hideOnMobile
          />
        </MaxWidthAbsolute>

        {/* Overlay */}
        <Absolute
          bg="rgba(255, 255, 255, 0.9)"
          borderTop="1px solid #EBEBEB"
          top="0"
          bottom="0"
          left="0"
          right="0"
        />

        {/* Content */}
        <Container pt={[60, null, null, 100]} pb={[60, null, 70]}>
          <Heading as="h3" maxWidth="785px" textAlign="center" mx="auto">
            Take your coaching to the next level with TopTekkers
          </Heading>

          <TextWithLink
            setCenter
            btnColor={theme.green}
            buttonText="Create an account"
            setWidth="579px"
            href="https://app.toptekkers.com"
          >
            Talent is important, but it&apos;s only part of the equation.  Every
            player should have a relentless enthusiasm for improving their
            skills to be the best they can be.  TopTekkers supports your
            players in their practise in a fun and challenging way.
          </TextWithLink>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    teamPitch: file(relativePath: { eq: "images/teamPitch.png" }) {
      ...imageFragmentMax
    }
    coachOnTheGo: file(relativePath: { eq: "images/coachApp.png" }) {
      ...imageFragmentMax
    }
    coachPlan: file(relativePath: { eq: "images/coachPlan.png" }) {
      ...imageFragmentMax
    }
    onTheGo: file(relativePath: { eq: "images/on-the-go.png" }) {
      ...imageFragmentMax
    }
    prismic: prismicTestimonialList(data: {page: {eq: "Coach Portal"}}) {
      data {
        page
        testimonialList: testimonial_list {
          testimonial {
            document {
              ... on PrismicTestimonial {
                data {
                  main_image {
                    alt
                    localFile {
                      ...imageFragmentMax
                    }
                  }
                  highlight
                  testimonial
                  profile_image {
                    alt
                    localFile {
                      ...imageFragmentProfilePic
                    }
                  }
                  profile_name
                  profile_title
                }
              }
            }
          }
        }
      }
    }
  }
`;

CoachPortalPage.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(CoachPortalPage);
