import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import Box from "../containers/box";
import Flex from "../containers/flex";
import Text from "../typography/text";
import Image from "../globals/image";

const Root = styled(Flex)`
  position: absolute;

  ${p => p.rowOnMob
    && `
    flex-direction: row;


    @media (min-width: ${p.theme.screenM}) {
      flex-direction: column;
    }`}
`;

const DataWrap = styled.div``;

const ProfileImage = styled(Box)`
  width: 93px;
  height: 93px;
  margin-right: 20px;

  ${p => p.rowOnMob
    && `@media (min-width: ${p.theme.screenM}) {
      margin-right: 0;
      margin-bottom: 20px;
    }`}
`;

const Profile = ({ data, rowOnMob, theme, ...rest }) => {
  const { name, title, img } = data;
  const profileImage = img.localFile.childImageSharp.fixed;
  
  return (
    <Root rowOnMob={rowOnMob} {...rest}>
      <ProfileImage rowOnMob={rowOnMob}>
        <Image fixed={profileImage} borderRadius="100px" alt={img.alt} />
      </ProfileImage>

      <DataWrap>
        <Text
          fontFamily={theme.headingFont}
          fontSize={[`22px`, null, `28px`]}
          mb={[`10px`, null, null, `20px`]}
          color={theme.blueDark}
        >
          {name}
        </Text>
        <Text fontSize="18px" lineHeight="24px" mb="0">
          {title}
        </Text>
      </DataWrap>
    </Root>
  );
};

Profile.propTypes = {
  data: PropTypes.PropTypes.object.isRequired,
  rowOnMob: PropTypes.bool,
  theme: PropTypes.PropTypes.object.isRequired,
};

export default withTheme(Profile);
