import React from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";

import Text from "../typography/text";

const CounterDivide = styled.span`
  margin-left: 8px;
  margin-right: 8px;

  @media (min-width: ${p => p.theme.screenM}) {
    margin-left: 14px;
    margin-right: 14px;
  }
`;
const Root = styled.div`
  font-family: ${p => p.theme.headingFont};
  color: ${p => p.theme.greyDarkFaded};
  position: absolute;
  right: 4px;
  bottom: -40px;

  * {
    display: inline-block;
    font-size: 14px;
  }
`;

const Counter = ({ current, max, theme }) => (
  <Root>
    <Text mb="0" color={theme.blueDark}>
      0{current}
    </Text>
    <CounterDivide>/</CounterDivide>
    <Text color={theme.greyLight} mb="0">
      0{max}
    </Text>
  </Root>
);

Counter.propTypes = {
  current: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(Counter);
