import styled from "styled-components";
import Text from "./text";

const PageTitle = styled(Text)`
  letter-spacing: 1.75px;
  color: ${p => p.color};
`;

PageTitle.defaultProps = {
  color: `#30b842`,
  lineHeight: `auto`,
  fontSize: [12, null, 14],
  mb: [`30px`, null, null, `50px`],
  textAlign: `left`,
};

export default PageTitle;
